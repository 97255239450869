import classNames from 'classnames';
import PropTypes from 'prop-types';

import ButtonStyles from '../../button.module.css';
import Typography from '../../typography.module.css';

const colorStyles = {
  primary: ButtonStyles.primary,
  primaryLarge: ButtonStyles.primaryLarge,
  secondary: ButtonStyles.secondary,
  tertiary: ButtonStyles.tertiary,
  link: Typography.link,
};

function Link({ children, color = 'link', className = '', ...rest }) {
  return (
    <a className={classNames(colorStyles[color], className)} {...rest}>
      {children}
    </a>
  );
}

Link.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'primaryLarge',
    'secondary',
    'tertiary',
    'link',
  ]),
  children: PropTypes.node.isRequired,

  'aria-level': PropTypes.string,
  'data-testid': PropTypes.string,
  id: PropTypes.string,
  itemProp: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  rel: PropTypes.string,
  style: PropTypes.object,
  href: PropTypes.string.isRequired,
};

export default Link;
