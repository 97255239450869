import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

import { POLICY_BASE_API } from '@pumpkincare/config';
import { buildAuthHeaders, responseDataBody } from '@pumpkincare/shared';

const BASE_URL = `${POLICY_BASE_API}/onboarding-steps`;

export function postOnboardingSteps(userId, quoteId) {
  return axios
    .post(BASE_URL, { user_id: userId, quote_id: quoteId })
    .then(responseDataBody);
}

export function getOnboardingSteps() {
  return fetchAuthSession().then(authSession =>
    axios.get(BASE_URL, buildAuthHeaders(authSession)).then(responseDataBody)
  );
}

export function patchOnboardingStep(stepId, payload) {
  const url = `${BASE_URL}/${stepId}`;

  return fetchAuthSession().then(authSession =>
    axios.patch(url, payload, buildAuthHeaders(authSession)).then(responseDataBody)
  );
}
