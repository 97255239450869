import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ButtonStyles from '../../button.module.css';
import Typography from '../../typography.module.css';

const colorStyles = {
  primary: ButtonStyles.primary,
  primaryLarge: ButtonStyles.primaryLarge,
  secondary: ButtonStyles.secondary,
  tertiary: ButtonStyles.tertiary,
  cta: ButtonStyles.cta,
  link: Typography.link,
};

function RouteLink({ className, color = 'link', children, ...rest }) {
  return (
    <Link className={classNames(className, colorStyles[color])} {...rest}>
      {children}
    </Link>
  );
}

RouteLink.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'primaryLarge',
    'secondary',
    'tertiary',
    'link',
    'cta',
  ]),
  children: PropTypes.node.isRequired,

  // ...rest via react-router-dom
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
    }),
  ]).isRequired,
  replace: PropTypes.bool,

  // ...rest via common native anchor properties
  download: PropTypes.bool,
  rel: PropTypes.string,
  target: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  role: PropTypes.string,
  id: PropTypes.string,
};

export default RouteLink;
