import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

import { POLICY_BASE_API, VET_BASE_API } from '@pumpkincare/config';
import { buildAuthHeaders, responseDataBody } from '@pumpkincare/shared';

export function postVetClinicSearchWithZip(term, zipcode) {
  const url = `${POLICY_BASE_API}/vet-practices/zipcode-suggestions`;

  return axios
    .post(url, { vet_name: term, zipcode })
    .then(response => response.data.body);
}

export function postCustomVets(payload) {
  const url = `${VET_BASE_API}/vets-custom`;

  return axios.post(url, payload).then(response => responseDataBody(response).data);
}

export function getAnswers(userId) {
  const url = `${POLICY_BASE_API}/users/${userId}/vet-form`;

  return fetchAuthSession().then(authSession => {
    return axios
      .get(url, buildAuthHeaders(authSession))
      .then(response => response.data.body);
  });
}
