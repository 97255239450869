import classNames from 'classnames';
import PropTypes from 'prop-types';

import ButtonStyles from '../../button.module.css';
import styles from './chip.css';

function Chip({
  classes,
  children,
  selected,
  large,
  error: { hasError },
  ...buttonProps
}) {
  return (
    <button
      className={classNames(styles.root, classes.root, ButtonStyles.secondary, {
        [styles.selected]: selected,
        [styles.large]: large,
        [styles.error]: hasError,
      })}
      {...buttonProps}
    >
      {children}
    </button>
  );
}

Chip.defaultProps = {
  classes: {},
  error: {},
  selected: false,
};

Chip.propTypes = {
  classes: PropTypes.object,
  selected: PropTypes.bool,
  large: PropTypes.bool,
  children: PropTypes.node.isRequired,
  error: PropTypes.shape({
    hasError: PropTypes.bool,
  }),

  // spread via ...buttonProps
  onClick: PropTypes.func,
  role: PropTypes.string,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
  'data-testid': PropTypes.string,
};

export default Chip;
