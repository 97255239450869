import { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const ONBOARDING_STEPS_PROVIDER_ERROR = new Error(
  'The hook must be used within a OnboardingStepsProvider'
);

const OnboardingModalSeen = createContext();

export default function OnboardingStepsProvider({ children }) {
  const [isModalSeen, setIsModalSeen] = useState(false);

  const value = useMemo(() => {
    return {
      isModalSeen,
      setIsModalSeen,
    };
  }, [isModalSeen]);

  return (
    <OnboardingModalSeen.Provider value={value}>
      {children}
    </OnboardingModalSeen.Provider>
  );
}

OnboardingStepsProvider.propTypes = {
  children: PropTypes.node,
};

export function useOnboardingModalSeen() {
  const context = useContext(OnboardingModalSeen);

  if (context === undefined) {
    throw ONBOARDING_STEPS_PROVIDER_ERROR;
  }

  return context;
}
