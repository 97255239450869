import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';

import {
  requestZipCodeValidity,
  US_STATES,
  validateEmail,
} from '@pumpkincare/shared';
import { Select, TextField, ZipcodeField } from '@pumpkincare/shared/ui';

import styles from './vet-clinic-form.css';

const invalidZipError =
  'Hmmm, we can’t find that one. Please enter a valid U.S. zip code.';

function VetClinicForm({ className, onCancel, onChange, defaultName }) {
  const [values, setValues] = useState({
    name: defaultName,
    address: '',
    zipcode: '',
    city: '',
    email: '',
    stateProvince: '',
  });

  const [zipcodeError, setZipcodeError] = useState(null);

  function isValidForm(inputState) {
    const { name, city, stateProvince, zipcode, email } = inputState || values;

    // required fields name, city, state, but also check for zip error
    if (!name || !city || !stateProvince || zipcodeError) return false;

    // if optional zipcode provided, is zipcode valid
    if (zipcode && zipcode.length !== 5) return false;

    // if optional email provided, is email valid
    return !(email && !validateEmail(email));
  }

  function handleZipcodeError(error) {
    setZipcodeError(error);

    onChange({ valid: isValidForm(), payload: values });
  }

  const debouncedValidateZipCode = useDebouncedCallback(value => {
    requestZipCodeValidity(value)
      .then(({ state, valid }) => {
        handleZipcodeError('');

        if (!valid && !state) {
          handleZipcodeError(invalidZipError);
        }
      })
      .catch(() => {
        handleZipcodeError(invalidZipError);
      });
  }, 500);

  function handleChange(key, value) {
    setValues(state => {
      const payload = {
        ...state,
        [key]: value,
      };

      onChange({ valid: isValidForm(payload), payload });

      return payload;
    });
  }

  function handleNameChange({ target: { value } }) {
    handleChange('name', value);
  }

  function handleAddressChange({ target: { value } }) {
    handleChange('address', value);
  }

  function handleCityChange({ target: { value } }) {
    handleChange('city', value);
  }

  function handleEmailChange({ target: { value } }) {
    handleChange('email', value);
  }

  function handleZipcodeChange({ target: { value } }) {
    handleChange('zipcode', value);
    setZipcodeError('');

    if (value.length === 5) {
      debouncedValidateZipCode(value);
    }
  }

  function handleStateChange({ value }) {
    handleChange('stateProvince', value);
  }

  function handleCancelIconClick() {
    onCancel();
  }

  return (
    <form
      className={classNames(styles.root, className)}
      onSubmit={e => e.preventDefault()}
    >
      <TextField
        value='My vet is not on this list'
        onChange={() => {}}
        classes={{ container: styles.fullField }}
        endAdornment={{ icon: 'close', onIconClick: handleCancelIconClick }}
        label='Vet clinic'
      />

      <TextField
        id='vet-name'
        name='vet-name'
        value={values.name}
        onChange={handleNameChange}
        classes={{ container: styles.fullField }}
        label='Vet Name'
      />

      <TextField
        id='address'
        name='address'
        label='Address (optional)'
        value={values.address}
        onChange={handleAddressChange}
        classes={{ container: styles.fullField }}
      />

      <TextField
        id='city'
        name='city'
        label='City'
        value={values.city}
        onChange={handleCityChange}
        classes={{ container: styles.halfField }}
      />

      <Select
        id='state'
        name='stateProvince'
        options={Object.keys(US_STATES).map(value => ({
          value,
          label: value,
        }))}
        onChange={handleStateChange}
        label='State'
        placeholder=''
        value={
          values.stateProvince && {
            value: values.stateProvince,
            label: values.stateProvince,
          }
        }
        classes={{ root: styles.halfField }}
      />

      <ZipcodeField
        id='zipcode'
        name='zipcode'
        label='Zipcode (optional)'
        value={values.zipcode}
        onChange={handleZipcodeChange}
        error={{ errorMessage: zipcodeError }}
        classes={{ container: styles.fullField }}
      />

      <TextField
        id='email'
        name='email'
        label='Email address (optional)'
        value={values.email}
        onChange={handleEmailChange}
        classes={{ container: styles.fullField }}
      />
    </form>
  );
}

VetClinicForm.defaultProps = {
  className: '',
  onCancel: () => {},
  onChange: () => {},
  defaultName: '',
};

VetClinicForm.propTypes = {
  className: PropTypes.string,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  defaultName: PropTypes.string,
};

export default VetClinicForm;
