import PropTypes from 'prop-types';

import SvgIcon, { SvgIconPropTypes } from './svg-icon';

function Bolt({ fill, stroke, ...rest }) {
  return (
    <SvgIcon fill='none' data-testid='bolt' {...rest}>
      <path
        d='M1.28261 13.9533L11.1844 1L8.42109 11.486H13.7174L4.96699 23L7.26973 13.9533H1.28261Z'
        fill={fill}
        stroke={stroke}
        strokeLinejoin='round'
      />
    </SvgIcon>
  );
}

Bolt.defaultProps = {
  fill: '#5368F5',
  stroke: '#5368F5',
};

Bolt.propTypes = {
  ...SvgIconPropTypes,
  stroke: PropTypes.string,
  fill: PropTypes.string,
};

export default Bolt;
