import { formatISODate } from '@pumpkincare/shared';

import { STATUS } from './pet-plans-utils';

export const WELLNESS_STATUS = STATUS;

/**
 * Do not import directly into code - exported only for testing
 * */
export function getWellnessIsRenewal(wellness) {
  if (!wellness.length) return {};

  const isRenewal = wellness[0].status === WELLNESS_STATUS.RENEW_PENDING;
  const renewalDate = isRenewal
    ? formatISODate(wellness[0].end_date, {
        format: 'MMM D, YYYY',
        inputTimezone: 'local',
      })
    : null;

  return { isRenewal, renewalDate };
}

/**
 * Do not import directly into code - exported only for testing
 * */
export function getWellnessIsDeclined(wellness) {
  if (!wellness.length) return {};

  const isDeclined =
    wellness[0].status === WELLNESS_STATUS.DECLINED &&
    !wellness.some(item => item.status === WELLNESS_STATUS.CANCELLED);
  const endDate = isDeclined
    ? formatISODate(wellness[0].end_date, {
        format: 'MMM D, YYYY',
        inputTimezone: 'local',
      })
    : null;

  return { isDeclined, endDate };
}

/**
 * Do not import directly into code - exported only for testing
 * */
export function getWellnessIsCancelled(wellness) {
  if (!wellness.length) return {};

  const cancelledWellness =
    !wellness.find(wellness => wellness.status === WELLNESS_STATUS.ACTIVE) &&
    wellness.find(
      wellness =>
        wellness.status === WELLNESS_STATUS.CANCELLED ||
        wellness.status === WELLNESS_STATUS.CANCEL_PENDING
    );

  const isCancelled =
    !!cancelledWellness ||
    [WELLNESS_STATUS.EXPIRED, WELLNESS_STATUS.VOIDED].includes(wellness[0].status);
  const isDeceased = cancelledWellness?.cancellation_reason === 'Deceased';

  return { isCancelled, isDeceased };
}

/**
 * Do not import directly into code - exported only for testing
 * */
export function getWellnessIsLapsed(wellness) {
  if (!wellness.length) return {};

  const isLapsed = wellness.some(item => item.status === WELLNESS_STATUS.LAPSED);
  return { isLapsed };
}

/**
 * Do not import directly into code - exported only for testing
 * */
export function getWellnessIsActive(wellness) {
  if (!wellness.length) return {};

  const isActive = wellness.some(item => item.status === WELLNESS_STATUS.ACTIVE);
  return { isActive };
}

export function getWellnessConfig(wellness) {
  const renewal = getWellnessIsRenewal(wellness);
  const declined = getWellnessIsDeclined(wellness);
  const cancelled = getWellnessIsCancelled(wellness);
  const lapsed = getWellnessIsLapsed(wellness);
  const active = getWellnessIsActive(wellness);

  return { active, renewal, declined, cancelled, lapsed };
}

/*
  returns the latest relevant plan for a pet - there are generally only two recent plans for a pet owner that are relevant:
  the current one and the renewing one
  this returns the current plan (which can include the canceled / expired pets too)
 */
export function getLatestRelevantWellness(wellness) {
  if (!wellness.length) return {};

  if (
    [
      WELLNESS_STATUS.ACTIVE,
      WELLNESS_STATUS.CANCELLED,
      WELLNESS_STATUS.CANCEL_PENDING,
      WELLNESS_STATUS.EXPIRED,
      WELLNESS_STATUS.LAPSED,
      WELLNESS_STATUS.VOIDED,
      WELLNESS_STATUS.PENDING,
    ].some(status => status === wellness[0].status)
  )
    return wellness[0];

  return (
    (wellness[1]?.status === WELLNESS_STATUS.VOIDED && wellness[2]) || wellness[1]
  );
}

export function getWellnessPrice(wellness, isAnnuallyCharged, isGroupFF) {
  if (!wellness.length) return {};

  const { price, group_enrolled } = getLatestRelevantWellness(wellness);

  if (isGroupFF && group_enrolled) return {};

  return {
    cost: isAnnuallyCharged ? price * 12 : price,
    frequency: isAnnuallyCharged ? 'year' : 'month',
  };
}

const PROGRAM_OPTIONS_ITEMS_LABELS = {
  EXAMS: 'Wellness Exams',
  VACCINES: 'Vaccinations',
  BLOODWORK: 'Routine Bloodwork',
  FECAL: 'Fecal Tests',
  HEARTWORM_MEDS: 'Tick / Flea / Heartworm Medications',
  SPAY_NEUTER_OR_DENTAL: 'Spay/Neuter Procedure or Routine Dental Cleaning',
  TELEHEALTH: 'PawSupport 24/7 Pet Health Helpline',
};

export function getWellnessEntitlements(wellness) {
  if (!wellness.length) return [];

  const { utilization } = getLatestRelevantWellness(wellness);

  return utilization
    .map(item => ({
      ...item,
      total: item.available,
      description: PROGRAM_OPTIONS_ITEMS_LABELS[item.entitlement],
      isUnlimited: item.entitlement === 'TELEHEALTH',
    }))
    .sort((a, b) => a.total - b.total);
}

const TIER_MAP = {
  'essential.puppy': 'Puppy Essential',
  'essential.dog': 'Dog Essential',
  'essential.kitten': 'Kitten Essential',
  'essential.cat': 'Cat Essential',
  'premium.puppy': 'Puppy Premium',
  'premium.dog': 'Dog Premium',
  'premium.kitten': 'Kitten Premium',
  'premium.cat': 'Cat Premium',
};

export function getWellnessDocuments(wellness) {
  if (!wellness.length) return [];

  const allowedStatuses = [
    WELLNESS_STATUS.ACTIVE,
    WELLNESS_STATUS.CANCELLED,
    WELLNESS_STATUS.CANCEL_PENDING,
    WELLNESS_STATUS.EXPIRED,
    WELLNESS_STATUS.VOIDED,
  ];
  const filteredWellness = wellness.filter(wellness =>
    allowedStatuses.some(status => status === wellness.status)
  );

  return filteredWellness.map(wellness => {
    return {
      id: wellness.id,
      name: `Customer Agreement - ${TIER_MAP[wellness.tier]} Plan`,
      subtitle: `${formatISODate(wellness.start_date, {
        format: 'MMM D, YYYY',
        inputTimezone: 'local',
      })} - ${formatISODate(wellness.end_date, {
        format: 'MMM D, YYYY',
        inputTimezone: 'local',
      })}`,
      // no wellness midterm changes for v1, can just look at [0] - when implementing midterm changes, look to getPolicyDocuments
      processingText: !wellness.documents[0]?.location
        ? "Your latest customer agreement isn't ready. Hang tight and check back soon."
        : '',
    };
  });
}

export function getPetsWithEmployerWellness(pets) {
  return pets.filter(
    pet =>
      pet.wellness?.length > 0 &&
      pet.wellness.every(wellness => wellness.group_enrolled)
  );
}
